<template>
  <div class="Login">
    <div class="Ligin-content">
      <div class="content_left_background">
        <div class="Login_form">
          <div class="new-login">
            <div class="login-box">
              <div class="login-copy">登录</div>
              <div class="login-logo">
                <img src="../assets/icon/logo.png" alt="" />
              </div>
              <div class="login-form">
                <div class="login-input" v-show="Inputlevel == 1">
                  <input
                    v-model="form.username"
                    :class="Errorstate ? 'ErrorClasss' : ''"
                    type="text"
                    :style="
                      EffectSwitchings ? '' : 'border: 2px solid #f0c54f;'
                    "
                    @click.stop="EffectSwitchin"
                    @blur="LoseFocus"
                  />
                  <div
                    :class="Errorstate ? 'ErrorClass' : ''"
                    :style="
                      EffectSwitching
                        ? 'top: 50%;'
                        : 'top: 0;color: rgb(229,151,0);font-weight: bold;'
                    "
                    class="input-cover"
                  >
                    手机号码
                  </div>
                </div>
                <div class="login-input" style="margin-top:20px;">
                  <input
                    v-model="form.password"
                    :class="Errorstate2 ? 'ErrorClasss' : ''"
                    type="password"
                    :style="
                      EffectSwitchings2 ? '' : 'border: 2px solid #f0c54f;'
                    "
                    @click.stop="EffectSwitchin2"
                    @blur="LoseFocus2"
                  />
                  <div
                    :class="Errorstate2 ? 'ErrorClass' : ''"
                    :style="
                      EffectSwitching2
                        ? 'top: 50%;'
                        : 'top: 0;color: rgb(229,151,0);font-weight: bold;'
                    "
                    class="input-cover"
                  >
                    密码（8到12位）
                  </div>
                </div>
                <div class="login2ndStep" v-show="Inputlevel === 2">
                  <!-- <a href="#">去注册</a> -->
                </div>
                <div class="login-actions">
                  <div class="login-action-left">
                    {{ CErrorText }}
                  </div>
                  <div class="login-action-left">
                    {{ EErrorText }}
                  </div>
                  <div class="login-action-right">
                    <el-button
                      v-loading="loading"
                      class="submit"
                      @click.stop="nextStep"
                    >
                      <!-- 下一步 -->
                      {{ TextMsg }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_right_background"></div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      loading: false,
      // 输入框效果切换
      EffectSwitching: true,
      EffectSwitching2: true,
      EffectSwitchings: true,
      EffectSwitchings2: true,
      // 输入框层级
      Inputlevel: 1,
      CErrorText: "",
      EErrorText: "",
      Errorstate: false,
      Errorstate2: false,
      TextMsg: "登录",
      // from表单
      form: {
        username: "",
        password: ""
      }
    };
  },
  created() {
    if (getToken()) {
      //
      this.$router.push({
        path: "/"
      });
      return;
    } else {
      // this.$message({
      //     message: '请先登录！！！',
      //     type: 'warning'
      // });
    }
    this.LoginStateRetention();
    this.UserNameStateRetention();
    this.LoseFocus();
    this.form = {}
  },
  methods: {
    // 登录状态修改
    loginshows() {
      this.loginshow = true;
      sessionStorage.setItem("liginstate", this.loginshow);
    },
    // 登录状态修改
    loginshowss() {
      this.loginshow = !this.loginshow;
      sessionStorage.setItem("liginstate", this.loginshow);
    },
    // 账号输入保留
    UserNameStateRetention() {
      if (sessionStorage.getItem("userName") != null) {
        this.form.username = sessionStorage.getItem("userName");
      }
    },
    // 登录页面状态保留
    LoginStateRetention() {
      switch (sessionStorage.getItem("liginstate")) {
        case "true":
          return (this.loginshow = true);
        case "false":
          return (this.loginshow = false);
        default:
          return undefined;
      }
    },
    // 返回按钮
    returnx() {
      if (this.Inputlevel === 2) {
        this.Inputlevel = 1;
        this.CErrorText = "";
        this.EErrorText = "";
        this.EffectSwitching2 = true;
        this.EffectSwitchings2 = false;
        this.Errorstate2 = false;
        this.TextMsg = "下一步";
      }
    },
    // 下一步
    nextStep() {
      if (!this.form.username) {
        this.$message({
          message: "请输入账号",
          type: "warning"
        });
        return;
      } else if (!this.form.password) {
        this.$message({
          message: "请输入密码",
          type: "warning"
        });
        return;
      } else {
        this.$store
          .dispatch("Login", this.form)
          .then(() => {
            this.loading = false;
            this.form = {};
            this.$router.push({
              path: "/PersonalCenter"
            });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 账号输入框失去焦点(如果输入框有数据，就保留选中状态)
    LoseFocus() {
      if (this.form.username) {
        this.EffectSwitching = false;
        this.EffectSwitchings = false;
        return;
      } else {
        this.EffectSwitching = true;
        this.EffectSwitchings = true;
      }
    },
    // 密码框失去焦点
    LoseFocus2() {
      if (this.form.possword) {
        this.EffectSwitching2 = false;
        this.EffectSwitchings2 = false;
        return;
      } else {
        this.EffectSwitching2 = true;
        this.EffectSwitchings2 = true;
      }
    },
    // 账号输入框点击事件
    EffectSwitchin() {
      this.EffectSwitching = false;
      this.EffectSwitchings = false;
      this.Errorstate = false;
      this.CErrorText = "";
      this.EErrorText = "";
    },
    // 密码框点击事件
    EffectSwitchin2() {
      this.EffectSwitching2 = false;
      this.EffectSwitchings2 = false;
      this.Errorstate2 = false;
      this.CErrorText = "";
      this.EErrorText = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.Login {
  width: 100%;
  height: calc(100vh - 160px);
  .Ligin-content {
    width: 100%;
    height: 100%;
    background: aqua;
    display: flex;
    .content_left_background {
      flex: 1;
      background: url("../assets/imgs/img1.jpg") no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      .Login_form {
        position: absolute;
        top: 50%;
        left: 40%;
        left: 100%;
        left: 150%;
        width: 645px;
        height: 605px;
        background-color: #fff;
        z-index: 10;
        transform: translateY(-50%);
        transform: translate(-50%, -50%);
        box-shadow: 0 0 20px 0px #111;
        .new-login {
          box-sizing: border-box;
          width: 100%;
          // height: calc(100% - 360px);
          height: 605px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-family: AboutCopy, PingFangSC, sans-serif;
          .login-box {
            width: 100%;
            padding: 20px;
            // border: 1px solid #e1e1e1;
            border-radius: 6.5px;
            position: relative;
            .login-logo {
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              padding: 20px 0 20px 0;
              img {
                // width: 252px;
                width: 120px;
              }
            }
            .login-copy {
              // font-style: italic;
              font-size: 32px;
              font-weight: bold;
              font-family: "微软雅黑";
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              color: #000;
              margin: 10px 0 10px 0;
              padding: 0 10px;
            }
            .login-form {
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              flex-flow: column;
              padding: 0px 40px;
              .login-input {
                box-sizing: border-box;
                position: relative;
                width: 100%;
                input {
                  box-sizing: border-box;
                  text-align: left;
                  padding: 0 40px 0 20px;
                  font-size: 16px;
                  height: 58px;
                  line-height: 58px;
                  border: 1px solid #e1e1e1;
                  border-radius: 5px;
                  color: #a1a1a1;
                  width: 100%;
                  outline: none;
                }
                .input-cover {
                  // width: 140px;
                  pointer-events: none;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  margin-left: 20px;
                  padding: 5px;
                  transition: 0.2s ease all;
                  background: #fff;
                  color: #a1a1a1;
                }
                .ErrorClass {
                  color: #ff4440 !important;
                }
                .ErrorClasss {
                  border: 2px solid #ff4440;
                }
              }
              .login2ndStep {
                height: 17px;
                font-size: 0.75rem;
                font-weight: bold;
                font-family: "PingFang SC", Arial, "Microsoft YaHei UI",
                  "Microsoft YaHei", serif;
                margin-top: 10px;
                width: 100%;
                text-align: right;
                a {
                  color: #cdcdcd;
                  text-decoration: none;
                }
              }
              .login-actions {
                margin-top: 50px;
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-flow: row;
                -webkit-box-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                align-items: center;
                .login-action-left {
                  color: #ff4440;
                }
                .login-action {
                  color: #cdcdcd;
                  cursor: pointer;
                }
                .login-action-right {
                  .submit {
                    background: #fcd467;
                    border: 1rpx solid rgba($color: #fcd467, $alpha: 0.5);
                    width: 88px;
                    height: 40px;
                    // line-height: 40px;
                    text-align: center;
                    color: #4d4d4d;
                    border-radius: 10px;
                    font-family: "PingFang SC", Arial, "Microsoft YaHei UI",
                      "Microsoft YaHei", serif;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
    .content_left_background::before {
      // margin-top: 80px;
      // height: calc(100vh - 80px);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      // background-color: rgba(#FCC008, 0.9);
      background-color: rgba(#fff, 0.3);
      z-index: 2;
    }
    .content_right_background {
      background: #222;
      flex: 1;
    }
  }
}
</style>
